import React from 'react';
import Footer from '../components/Footer';

const Carrier = () => {
  return (
    <div className='text'>
      <p style={{ color: 'black' }}>FLAWLESS TEAMWORK</p>

      <img 
        src="/Assets/carrier5.jpg" 
        alt="Carrier Image"  
        style={{ width: '100%', height: '400px' }} 
      />

      <div className='title'>
        <h2 
          style={{ 
            color: 'Orange', 
            marginBottom: '0px', 
            textAlign: 'center', 
            textDecorationLine: 'underline',
            padding:'20px', 
            fontSize:'30px'
          }}
        >
          FLAWLESS TEAMWORK.
        </h2>
        <div className='text'>
          <p
            style={{
              backgroundColor: 'darkgray',
              width: '100%',
              height: '400px',
              marginTop:'2px',
              marginLeft:'0px',
              display:'flex',
              color:'black',
              textAlign:'left',
              paddingLeft:'20px'
            }} 
          >
            At Musale Construction, we pride ourselves on perfect execution of all our projects. The level of excellence has been achieved by our flawless teamwork, an eye for detail, a non-compromising attitude, a passion for putting our best into every task as well as our ability and desire to embrace challenges.<br/>
            The workforce of Musale Construction is a marvel collection of dreamers, achievers, and believers in their respective domains of expertise. All the departments have the best minds and experience that get together to plan, execute, and maintain any project. From a worker on-site to someone who holds the highest designation in Administration, our company strives together as a growing and flourishing corporate family.
              
            <img 
              src="/Assets/teamwork.jpg" 
              alt="Teamwork Image"  
              style={{ width: '40%', height: '300px', display:'flex', paddingRight:'9px',paddingLeft:'7px',paddingBottom:'30px',
                transition:"opacity 0.5s ease"
              }} 
            />
          </p>
        </div>
      </div>

      <div className='title'>
        <h2 
          style={{ 
            color: 'orange', 
            marginBottom: '0px', 
            textAlign: 'center', 
            textDecorationLine: 'underline',
            padding:'20px', 
            fontSize:'30px'
          }}
        >
          OUR HR PHILOSOPHY
        </h2>
        <p
          style={{
            width: '100%',
            height:'auto', // Adjust the height to auto
            display:'flex',
            marginTop:'2px',
            marginLeft:'0px',
            textAlign:'left',
            paddingLeft:'20px',
            paddingTop: '10px', // Reduce padding at the top
          }} 
        >
          We have an open work environment and have a diverse, highly skilled, productive, healthy, and efficient workforce. We offer challenging assignments, a family kind of working environment, professional management and ample opportunities to train, learn, and execute the most demanding projects, and in turn, get recognition in the form of exceptional rewards.<br/>
          Each Individual makes a difference in the working Environment. Building human competencies and capabilities is critical to our continued growth and success. Employees are intellectually and emotionally engaged. Fair and lawful treatment of all employees and Concern for the individual. Alignment of organizational goals with Individual goals. Recognize each individual’s contribution in the growth and development of the Company. Treat all Musale Group employees with respect and dignity.
          
          <img 
            src="/Assets/hiring1.jpg" 
            alt="Hiring Image"  
            style={{ width: '100%', height: '300px', display:'flex', justifyContent:'center', borderLeft:'4px solid black' }} 
          />
        </p>
      </div>

      {/* Carrier News Section */}
      <div className='title'>
        <h2 
          style={{ 
            color: 'orange', 
            marginBottom: '0px', 
            textAlign: 'center', 
            textDecorationLine: 'underline',
            padding:'20px', 
            fontSize:'30px'
          }}
        >
          CARRIER NEWS
        </h2>
        <p
          style={{
            width: '100%',
            height:'400px',
            display:'flex',
            marginTop:'2px',
            marginLeft:'0px',
            textAlign:'left',
            paddingLeft:'20px',
            paddingTop: '20px',
            backgroundColor: 'lightgray'
          }} 
        >
          Stay updated with the latest news and updates from Musale Construction. We are constantly growing, and we want you to be a part of our journey. Check back regularly for new job postings, events, and announcements that may be of interest to you.
          
          <img 
            src="/Assets/carrer.jpeg" 
            alt="News Image"  
            style={{ width: '40%', height: '300px', display:'flex', paddingRight:'9px',paddingLeft:'7px', paddingBottom:'30px', marginLeft: 'auto' }} 
          />
        </p>
      </div>
      <Footer/>
    </div>
  );
}

export default Carrier;
