import React from "react";
import './OurClients.css';

const OurClients = () => {
  return (
    <div className="clients-container">
      <h1>Our Clients</h1>
      <div className="clients-box">
        {clients.map((data, index) => (
          <div key={index} className="client-logo">
            <img
              src={data.url}
              alt={`Client ${index + 1}`}
              className="client-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurClients;

const clients = [
  {
    url: "./Assets/21.jpg",
  },
  {
    url: "./Assets/22.jpg",
  },
  {
    url: "./Assets/23.jpg",
  },
  {
    url: "./Assets/24.jpg",
  },
  {
    url: "./Assets/25.jpg",
  },
  {
    url: "./Assets/26.jpg",
  },
];

