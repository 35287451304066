import React from 'react';
import "../components/MachinaryIcon.css"

const MachinaryIcon = () => {
  return (
    <div className="machinary-icon-container">
      <div className="machinary-title">
        <h3>MACHINERY STRENGTH</h3>
      </div>

      <div className="machinary-icons-grid">
        {machinaryIcons.map((item) => (
          <div key={item.id} className="machinary-icon-card">
            <img className="machinary-icon-image" src={item.image} alt={item.id} />
            <div className="machinary-icon-details">
              <span className="machinary-icon-item">{item.Item}</span>
              <p className="machinary-icon-title">{item.id}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MachinaryIcon;

const machinaryIcons = [
  { id: 'Concrete batch mix plant', image: 'https://patelinfra.com/images/financila_info/Concrete_Batch_Mix_Plants.jpg', Item: 23 },
  { id: 'Hot mix plants batch type', image: 'https://patelinfra.com/images/financila_info/Hot_Mix_Plants_Batch_Type.jpg', Item: 9 },
  { id: 'Crusher plants screening plants sand plants', image: 'https://patelinfra.com/images/financila_info/Crusher_Plants.jpg', Item: 18 },
  { id: 'Asphalt pavers', image: 'https://patelinfra.com/images/financila_info/Asphalt_Pavers.jpg', Item: 21 },
  { id: 'Automatic slip form concrete pavers', image: 'https://patelinfra.com/images/financila_info/Automatic_Slip_Form_Concrete_Pavers.jpg', Item: 3 },
  { id: 'Milling machines', image: 'https://patelinfra.com/images/financila_info/Milling_Machines.jpg', Item: 2 },
  { id: 'Rollers And compactors', image: 'https://patelinfra.com/images/financila_info/Rollers_Compactors.jpg', Item: 82 },
  { id: 'Loaders and excavators', image: 'https://patelinfra.com/images/financila_info/Loaders_Excavators.jpg', Item: 102 },
];
