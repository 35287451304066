import React from 'react';
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const Footer = () => {
  return (
<div className="footer-container">
      <div className="footer-content-container">
        <div className="footer-column">
          <h6 className="footer-title">Musale Construction</h6>
          <p className="footer-text">© 2024 Company Name. All rights reserved.</p>
        </div>

        <div className="footer-column">
          <h6 className="footer-title">Navigation</h6>
          <div className="footer-links">
            <a href="/" className="footer-link">Home</a>
            <a href="/about" className="footer-link">About Us</a>
            <a href="/services" className="footer-link">Services</a>
            <a href="/contact" className="footer-link">Contact</a>
          </div>
        </div>

        <div className="footer-column">
          <h6 className="footer-title">Follow Us</h6>
          <div className="social-icons">
            <a href="https://www.facebook.com" className="social-icon" aria-label="Facebook">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com" className="social-icon" aria-label="Instagram">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="mailto:email@example.com" className="social-icon" aria-label="Email">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-content">
          © Copyright 2024 | Musale Construction
        </div>
        <div className="footer-content">
          Designed By Romo Technologies
        </div>
      </div>
    </div>
  );
};

export default Footer;
