import React, { useState } from 'react';
import './ContactUs.css'; // Import the CSS file
import Footer from '../components/Footer';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [inquiry, setInquiry] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle form submission
  };

  return (
    <div className="container">
      <img src="/Assets/contact-us-banner.jpg" alt="Contact Us" className="banner-image" />

      <div className="form-container">
        <div className="form-box">
          <h2 className="form-title">Contact Us</h2>
          <form onSubmit={handleSubmit}>
            {[
              { id: 'name', label: 'Name:', type: 'text', value: name, onChange: setName },
              { id: 'email', label: 'Email:', type: 'email', value: email, onChange: setEmail },
              { id: 'contactNumber', label: 'Contact Number:', type: 'tel', value: contactNumber, onChange: setContactNumber },
              { id: 'pinCode', label: 'Pin Code:', type: 'text', value: pinCode, onChange: setPinCode },
            ].map(({ id, label, type, value, onChange }) => (
              <div key={id} className="input-group">
                <label htmlFor={id} className="input-label">{label}</label>
                <input
                  type={type}
                  id={id}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  placeholder={`Enter your ${id}`}
                  required
                  className="input-field"
                />
              </div>
            ))}
            <div className="input-group">
              <label htmlFor="inquiry" className="input-label">Inquiry:</label>
              <textarea
                id="inquiry"
                value={inquiry}
                onChange={(e) => setInquiry(e.target.value)}
                placeholder="Enter your inquiry"
                required
                className="input-field"
              ></textarea>
            </div>
            <div>
              <button type="submit" className='submit-btn'>Submit</button>
            </div>
          </form>
        </div>

        <div className="info-box">
          <h3 className="info-title">REGISTERED OFFICE</h3>
          <p className="info-text">Musale Construction</p>
          <p className="info-text">( CIN:                 )</p>
          <p className="info-text">Musale Construction, Bhagyashree Apartment,</p>
          <p className="info-text">Trimurti Nagar, Nagpur-440022</p>
          <p className="info-text">India.</p>
          <p className="info-text">Ph: +91 9881104515</p>
          <p className="info-text">Ph: +91 9823102625</p>
          <p className="info-text">Email: musale.construction@rediffmail.com</p>
          <div className="responsive-map">
            <iframe
              src="https://www.google.com/maps?q=21.113295,79.041226&z=15&output=embed"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
