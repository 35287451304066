import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Avatar
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <List>
      <ListItem
        component={Link}
        to="/"
        onClick={() => setDrawerOpen(false)}
        className="drawer-list-item"
      >
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem
        component={Link}
        to="/carrier"
        onClick={() => setDrawerOpen(false)}
        className="drawer-list-item"
      >
        <ListItemText primary="Carrier" />
      </ListItem>
      <ListItem
        component={Link}
        to="/about"
        onClick={() => setDrawerOpen(false)}
        className="drawer-list-item"
      >
        <ListItemText primary="About Us" />
      </ListItem>
      <ListItem
        component={Link}
        to="/gallery"
        onClick={() => setDrawerOpen(false)}
        className="drawer-list-item"
      >
        <ListItemText primary="Gallery" />
      </ListItem>
      <ListItem
        component={Link}
        to="/contact"
        onClick={() => setDrawerOpen(false)}
        className="drawer-list-item"
      >
        <ListItemText primary="Contact Us" />
      </ListItem>
      <ListItem
        onClick={() => {
          navigate('/login');
          setDrawerOpen(false);
        }}
        className="drawer-list-item"
      >
        <ListItemText primary="Login" />
      </ListItem>
    </List>
  );

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#fff' }}>
    <Toolbar>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2} // Adjusted spacing for better alignment
          component={Link}
          to="/"
          sx={{ textDecoration: 'none' }}
        >
          <Avatar
            src='../Assets/MC_Logo.jpg' 
            alt="Logo"
            sx={{ width: 50, height: 50, borderRadius: 0 }} // Adjust size to match the example
          />
          <Stack direction="column" spacing={0.5} sx={{ textDecoration: 'none' }}>
            <Typography
              variant="h5"
              sx={{ color: 'blue', fontWeight: 'bold', fontSize: '24px' }} // Adjust font size and weight
            >
              MUSALE CONSTRUCTION
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'black',
                fontSize: '16px', // Adjust font size
                position: 'relative',
                display: 'inline-block',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '2px',
                  bottom: '-2px',
                  left: '0',
                  backgroundColor: 'blue',
                },
              }}
            >
              BUILDERS AND CONTRACTORS
            </Typography>
            </Stack>
          </Stack>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <div className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerToggle}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <Stack
              direction="row"
              spacing={8}
              style={{ color: 'black' }}
            >
              <Button
                color="inherit"
                component={Link}
                to="/"
                className={`nav-button ${
                  location.pathname === '/' ? 'nav-button-active' : ''
                }`}
              >
                Home
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/carrier"
                className={`nav-button ${
                  location.pathname === '/carrier' ? 'nav-button-active' : ''
                }`}
              >
                Career
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/about"
                className={`nav-button ${
                  location.pathname === '/about' ? 'nav-button-active' : ''
                }`}
              >
                About Us
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/gallery"
                className={`nav-button ${
                  location.pathname === '/gallery' ? 'nav-button-active' : ''
                }`}
              >
                Gallery
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/contact"
                className={`nav-button ${
                  location.pathname === '/contact' ? 'nav-button-active' : ''
                }`}
              >
                Contact Us
              </Button>
              <Button
                onClick={() => navigate('/login')}
                variant="contained"
              >
                Login
              </Button>
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
