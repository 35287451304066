import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  inputAdornmentClasses,
} from "@mui/material";
import data from "../data.json";
import "../App.css";
import "./Home.css";
// import Video from "../components/Video";
import Footer from "../components/Footer";
import OurClients from "../components/OurClients";
import SliderImage from "../components/SliderImage/SliderImage";
// import { faHandPointRight } from '@fortawesome/free-solid-svg-icons'; // Import the specific icon
export default function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const incrementIndex = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(incrementIndex, 2000);
    return () => clearInterval(intervalId);
  }, [currentImageIndex]);

  return (
    <div style={{ paddingTop: "70px", overflowX: "hidden" }}>
      <SliderImage />
      <Container>
      <Box sx={{ marginTop: 4, padding: 2, backgroundColor: "#f7f7f7" }}>

      <div className="expertise-container">
        <div className="expertise-row">
          <div className="expertise-image">
            <img src="Assets/Experties.png" alt="Expertise Image" />
          </div>
          <div className="expertise-content">
            <p className="expertise-paragraph">
              Musale construction has been a connoisseur of infrastructure development
              for the last 29 years! Our focus on excellence and precision has allowed
              us to revolutionize the construction industry.
            </p>
            <p className="expertise-paragraph">
              We take pride in being one of the oldest and most efficient companies in
              the construction industry and have unlocked many achievements in the
              stellar journey of twenty-nine years. Musale Construction has become a
              symbol of trust and innovation in the construction industry.
            </p>
          </div>
        </div>
        <div className="expertise-highlight">
          <p>
            Our operation domains can be classified into two major fields:
            Construction contract business and the second is the development of
            roads and highway networks across the country. We offer EPC services for
            reputed clients specializing in the roads and highways sector, including
            long stretch highways, dam, irrigation, Transport material supply, 2
            stone crusher, 3 Petrol pump and a vivid range of building and other
            civil construction projects. The second branch of our business
            concerning the development of roads and highway projects is currently
            working on the hybrid annuity model ("HAM") basis (the "Annuity
            Business"), and through our Subsidiaries completed build, operate,
            transfer ("BOT") annuity projects.
          </p>
        </div>
      </div>


        <div className="expertise-grid-container">
        <div className="expertise-grid">
          {expertiseData.map((item, index) => (
            <div className="expertise-grid-item" key={index}>
              <div className="expertise-card">
                <img
                  src={item.image}
                  alt={item.title}
                  className="expertise-image"
                />
                <h5 className="expertise-title-img">{item.title}</h5>
                <p className="expertise-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        </div>
      </Box>
      <div className="philosophy-section">
        <div className="gradient-overlay"></div>

        <div className="philosophy-title">
          <h2>OUR</h2>
          <h2>PHILOSOPHY</h2>
        </div>

        <div className="philosophy-content">
          <ul className="philosophy-list">
            <li>
                  <span className="arrow">&#10003;</span>

              To bring in a high level of execution skills, meet customer benchmarks serve customers and: through timely completion of projects without compromising on quality.
            </li>
            <li>
                  <span className="arrow">&#10003;</span>

              To improve on existing quality-system in its operations.
            </li>
            <li>
                  <span className="arrow">&#10003;</span>

              To achieve greater productivity and safety standards.
            </li>
            <li>
                  <span className="arrow">&#10003;</span>

              To develop human resources and improve employee attitudes.
            </li>
            <li>
                  <span className="arrow">&#10003;</span>

              To maintain good growth of net worth and build on the assets of the company.
            </li>
            <li>
                  <span className="arrow">&#10003;</span>

              To be a market leader and a highly dependable services provider.
            </li>
            <li>
            <span className="arrow">&#10003;</span>

              To develop partnerships for growth and diversification.
            </li>
          </ul>
        </div>
      </div>


      <div className="section-container">
        <div className="section-content reverse">
          <div className="section-image">
            <img src="Assets/VisionMission_.png" alt="Vision & Mission" />
          </div>
          <div className="section-text">
            <h2>VISION & MISSION</h2>
            <ul>
              <li>Musale Construction shall be a leading global enterprise in world-class infrastructure development and environment management through sustainable growth.</li>
              <li>We shall ensure quality, reliability, and continuous technology upgradation thereby enhancing the value of all our stakeholders.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-container">
        <div className="section-content">
          <div className="section-image">
            <img src="Assets/Our_Values.png" alt="Values" />
          </div>
          <div className="section-text">
            <h2>VALUES</h2>
            <ul>
              <li>Musale Construction in achieving its vision shall promote high standards of:</li>
              <li>Integrity</li>
              <li>Customer Satisfaction</li>
              <li>Work Culture</li>
              <li>Employee Sense of Belonging</li>
              <li>Innovation</li>
              <li>Safety, Health & Environment</li>
            </ul>
          </div>
        </div>
      </div>
  
      <div className="working-department-container">
      <h1 className="title">Working Department</h1>
      <div className="client-list">
        {clientData.map((client, index) => (
          <div key={index} className="client-item">
            <a href="#" className="client-link">
              {client.name}
            </a>
          </div>
        ))}
      </div>
    </div>
      </Container>
      <OurClients />
      <Footer />
    </div>  
  );
}

// Data for Our Expertise section
const expertiseData = [
  {
    title: "Road Projects",

    image: "/Assets/road1.jpg",
  },
  {
    title: "Dam Projects",

    image: "/Assets/dam.jpg",
  },
  {
    title: "Irrigation Projects",

    image:
      "https://tse4.mm.bing.net/th?id=OIP.5wAfoU1wO8mIGVGEYStbBgHaEc&pid=Api&P=0&h=180",
  },
  {
    title: "Transport Material Supply",

    image:
      "https://tse2.mm.bing.net/th?id=OIP.b6_3ak0IhMYgV-VuOaZxYQHaD4&pid=Api&P=0&h=180",
  },
];

// Data for Client section
const clientData = [
  {
    name: "Pradhan Mantri Gram Sadak Yogna (PMGSY)",
  },
  {
    name: "Mukhya Mantri Gram Sadak Yogna (MMGSY)",
  },
  {
    name: "Public Works Department (PWD)",
  },
  {
    name: "Vidarbha Irrigation Development Corporation (VIDC)",
  },
];
