import React, { useState } from "react";
import "./AboutUs.css"
import FinancialGrid from "../components/FinancialGrid";
import MachinaryIcon from "../components/MachinaryIcon";
import Footer from "../components/Footer";

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="about-us-container">
      <img
        src="https://www.shunmugasteel.com/images/about-us.png"
        alt="Scenic view of a mountain landscape"
        className="about-us-image"
      />

      <div className="about-us-content">
        <div className="about-us-header">
          <h2 className="about-us-title">OUR</h2>
          <h2 className="about-us-title">COMPANY</h2>
        </div>
        <div className="about-us-description">
          <p className="about-us-paragraph">
          Musale Construction was incorporated on 5th October 1989. The
                Company was established with an objective to engage in the
                business of fabrication, erection, and commissioning related
                works required for Infrastructure and Irrigation Project. M/s
                Musale Construction was formed as a partnership concern by
                Musale Family as partners, which came into existence since 1989.
                We are an integrated construction and infrastructure development
                and management company in Vidarbha Region. Since commencing our
                business in 1989, we have serviced a diverse range of
                construction and infrastructure projects in sectors as varied as
                transportation, irrigation, residential, commercial, and retail
                property. We are headquartered in Nagpur and have five branch
                offices to manage our business operations throughout Vidarbha.
          </p>
          <p className="about-us-paragraph">
          We operate principal business segments: a construction business
                which is operated by our Company. A majority of our development
                projects are public private partnerships and are operated by
                separate special-purpose vehicles promoted by our Company and
                the Government. We own a large fleet of sophisticated
                construction equipment, including: crushing plants, hot mix
                plants, wet mix plants, asphalt batching plants, concrete
                batching plants, excavators, rock breakers, graders, pavers,
                compactors, tower cranes, dozers, bar bending, and cutting
                machines. Our workforce, as at September 30, 2009, consisted of
                508 full-time employees on a standalone basis. Our workforce,
                machinery assets, financial net worth, and past execution
                capabilities enable us to undertake many large-scale projects.

          </p>
        </div>
      </div>

      <div className="our-strength-section">
        <h2 className="section-title">OUR STRENGTH</h2>
        <div className="strength-content">
          <div className="strength-text">
            <h1 className="strength-heading">A TEAM YOU CAN TRUST.</h1>
            <p className="strength-paragraph">
            The workforce of Musale Construction is a marvel collection of
            dreamers, achievers, and believers in their respective domains of
            expertise. All the departments have the best minds and experience
            that gets together to plan, execute, and maintain any project. From
            a worker on-site to someone who holds the highest designation in
            Administration, our company strives together as a growing and
            flourishing corporate family.
            </p>
          </div>
          <div className="expertise-grid">
            {icons.map((icon) => (
              <div className="expertise-grid-item" key={icon.id}>
                <div className="expertise-card">
                  {icon.image}
                  <h3 className="expertise-title-img">{icon.id}</h3>
                  <p className="expertise-description">{icon.item}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
        <MachinaryIcon/>
        <Footer/>
    </div>
  );
};

export default AboutUs;

const icons = [
  {
    id: "Technical & Plant Machinary",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_1.png"
        alt="Technical & Plant Machinery Icon"
      />
    ),
    item: 535,
  },
  {
    id: "Store And Purchase",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_3.png"
        alt="Store And Purchase Icon"
      />
    ),
    item: 191,
  },
  {
    id: "Hr and Admin",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_5.png"
        alt="HR and Admin Icon"
      />
    ),
    item: 16,
  },
  {
    id: "Supervisor",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_2.png"
        alt="Supervisor Icon"
      />
    ),
    item: 5,
  },
  {
    id: "Finance And Account",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_8.png"
        alt="Finance And Account Icon"
      />
    ),
    item: 55,
  },
  {
    id: "HSE",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_9.png"
        alt="HSE Icon"
      />
    ),
    item: 78,
  },
  {
    id: "IT",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_4.png"
        alt="IT Icon"
      />
    ),
    item: 22,
  },
  {
    id: "Marketing",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_7.png"
        alt="Marketing Icon"
      />
    ),
    item: 10,
  },
  {
    id: "Legal",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_10.png"
        alt="Legal Icon"
      />
    ),
    item: 3,
  },
  {
    id: "Other",
    image: (
      <img
        style={{ width: "80px", height: "65px" }}
        src="https://patelinfra.com/images/financila_info/financial_6.png"
        alt="Other Icon"
      />
    ),
    item: 1937,
  },
];
