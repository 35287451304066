import React from "react";
import video from '../../src/Assets/video (online-video-cutter.com).mp4';
import "./Gallery.css";
import Footer from "../components/Footer";

// Dynamically import all images from the Assets folder
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../Assets', false, /\.(jpg|jpeg|png|gif)$/));

// Insert the video into the images array where you want it to appear
const galleryItems = [
  ...images.slice(0, 2), // First part of images
  { video: true, src: video },
  ...images.slice(2) // Rest of the images
];

const Gallery = () => {
  return (
    <div className="gallery-container">
      <div className="images-container">
        {galleryItems.map((item, index) => (
          <div key={index} className="image-item">
            {item.video ? (
              <video className="gallery-video" autoPlay loop muted src={item.src} />
            ) : (
              <img src={item} alt={`Gallery image ${index + 1}`} />
            )}
          </div>
        ))}
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
};

export default Gallery;
